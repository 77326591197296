import { useNavigate } from "react-router-dom";
import { FaHome, FaUser, FaSignOutAlt } from "react-icons/fa";

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  useMediaQuery,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Stack,
  FormControl,
  Select,
  Pagination,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import MenuDrawer from "../../../components/MenuDrawer/MenuDrawer";
import AppBar from "../../../components/AppBar/AppBar";
import { styled, useTheme } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import { Close, Warning } from "@mui/icons-material";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar/TitleWithSearchBar";
import { backendUrl } from "../../../constants/config";
import SnackbarComponent from "../../../components/Snackbar/Snackbar";

const Home = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [err, setErr] = useState(null);
  const [success, setSuccess] = useState(null);

  const smallScreen = useMediaQuery("(max-width:650px)");

  const [deleteModalState, setdeleteModalState] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUser] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarState(false);
  };

  const fetchTrackingData = async (userEmail) => {
    try {
    } catch (err) {
      setErr("Error fetching Tracking data.");
      console.log(err);
    }

    const response = await axios.get(
      `${backendUrl}/user/getTrackingData?userEmail=${userEmail}`
    );

    if (response.data.trackingData) {
      setTableData(response.data.trackingData);
    }
  };

  useEffect(() => {
    const talentSyncUser = JSON.parse(localStorage.getItem("TalentSyncUser"));

    if (talentSyncUser && talentSyncUser.name) {
      setUser(talentSyncUser);
    }

    fetchTrackingData(talentSyncUser.email);
  }, []);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const sitesColumns = [
    {
      field: "S.No",
      headerName: "S.No",
      width: 80,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "500",
            color: "black",
            textAlign: "center",
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center", // Center horizontally
            height: "100%",
          }}
        >
          {params.api.getAllRowIds().indexOf(params.id) + 1}
        </Box>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      minWidth: 200,
      headerAlign: "center",

      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#1a1a1a",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "position",
      headerName: "Position",
      flex: 1.5,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "company",
      headerName: "Company",
      flex: 1.5,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "linkedin",
      headerName: "LinkedIn",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <a
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontSize: "12px",
              color: "#0073b1",
              textDecoration: "none",
            }}
          >
            View Profile
          </a>
        </Box>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.5,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              color: "#1a1a1a",
              wordWrap: "break-word",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "open_trucker",
      headerName: "Open Trucker",
      flex: 1.5,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              color: "#1a1a1a",
              wordWrap: "break-word",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date/Time",
      flex: 1.5,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        // Check if value exists and is valid
        const date = params.value ? new Date(params.value) : null;

        // Format the date manually
        const options = {
          weekday: "long",
          day: "numeric",
          month: "short",
          year: "numeric",
        };
        const timeOptions = {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        };
        const formattedDate = date
          ? `${date.toLocaleDateString(
              "en-US",
              options
            )} / ${date.toLocaleTimeString("en-US", timeOptions)}`
          : "Invalid Date";

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                color: "#1a1a1a",
                wordWrap: "break-word",
              }}
            >
              {formattedDate}
            </Typography>
          </Box>
        );
      },
    },
  ];

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#f0f0f0",
      borderBottom: "2px solid #ddd",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
      fontSize: "14px",
    },
    "& .MuiDataGrid-row": {
      "&:nth-of-type(odd)": {
        backgroundColor: "#fafafa",
      },
      "&:nth-of-type(even)": {
        backgroundColor: "#fff",
      },
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    },
    "& .MuiDataGrid-cell": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }));

  const [currentPage, setcurrentPage] = useState(1);
  const [totalCount, settotalCount] = useState(0);
  const [totalPages, settotalPages] = useState(1);
  const [siteId, setsiteId] = useState("");
  const [siteName, setsiteName] = useState("");
  const [numOfProductPerPage, setnumOfProductPerPage] = useState(10);
  const startIndex = (currentPage - 1) * numOfProductPerPage + 1;
  const endIndex = Math.min(startIndex + numOfProductPerPage - 1);

  const [assignedSupervisor, setassignedSupervisor] = useState("");
  const [assignedSupervisorId, setassignedSupervisorId] = useState("");

  const [search, setSearch] = useState("");

  const [loading, setloading] = useState(false);
  const [snackbarState, setsnackbarState] = useState(false);

  const [siteStatus, setsiteStatus] = useState("");
  const [newSiteStatus, setnewSiteStatus] = useState("");

  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    seterror(false);
  };

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, left: open });
  };

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer admin toggleDrawer={toggleDrawer} state={state} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Snackbar open={error} autoHideDuration={2000} onClose={handleCloseError}>
        <Alert
          onClose={handleCloseError}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar> */}

      {/* <Snackbar
        open={snackbarState}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={newSiteStatus === "active" ? "Site Opened" : "Site Closed"}
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      /> */}

      <SnackbarComponent
        err={err}
        success={success}
        setErr={setErr}
        setSuccess={setSuccess}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Talent Sync Tracker" toggleDrawer={toggleDrawer} />

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        ></Menu>

        <Grid container p={2}>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            >
              <TitleWithSearchBar
                title="Tracker"
                search={search}
                setSearch={setSearch}
                halfWidth
              />

              {tableData && (
                <StyledDataGrid
                  rows={
                    tableData?.filter((val) => {
                      const searchLower = search.toLowerCase();
                      return (
                        val?.name?.toLowerCase().includes(searchLower) ||
                        val?.position?.toLowerCase().includes(searchLower) ||
                        val?.company?.toLowerCase().includes(searchLower) ||
                        val?.email?.toLowerCase().includes(searchLower)
                      );
                    }) || []
                  }
                  columns={sitesColumns}
                  pagination
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: numOfProductPerPage },
                    },
                  }}
                  pageSizeOptions={[5, 10, 100]}
                  sx={{
                    borderRadius: "12px",
                    border: "none",
                    "& .MuiDataGrid-virtualScroller": {
                      minHeight: "70vh",
                    },
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Home;
