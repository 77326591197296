import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Menu,
  Button,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import MenuDrawer from "../../../components/AdminMenuDrawer/MenuDrawer";
import AppBar from "../../../components/AppBar/AppBar";
import { styled } from "@mui/material/styles";
import axios from "axios";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar/TitleWithSearchBar";
import { backendUrl } from "../../../constants/config";
import SnackbarComponent from "../../../components/Snackbar/Snackbar";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  // backdropFilter: "blur(.5px) sepia(.5%)",
  "& .MuiDialog-paper": {
    borderRadius: "20px",
  },
  "& .MuiPaper-root": {
    maxWidth: "none",
  },
}));
const AdminDashboard = () => {
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [err, setErr] = useState(null);
  const [success, setSuccess] = useState(null);
  const [tableData, setTableData] = useState();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [trackerModalOpen, setTrackerModalOpen] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const toggleTrackerModal = () => setTrackerModalOpen(!trackerModalOpen);
  const toggleProfileModal = () => setProfileModalOpen(!profileModalOpen);
  const [selectedUsername, setSelectedUserName] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const talentSyncUser = JSON.parse(localStorage.getItem("TalentSyncUser"));

    if (talentSyncUser && talentSyncUser.name) {
      setUser(talentSyncUser);
    }

    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${backendUrl}/user/fetchAllUsers`);

      if (response.data.users.length > 0) {
        setUsers(response.data.users);
      }
    } catch (err) {
      setErr("Error fetching users.");
    }
  };

  const fetchTrackingData = async (userEmail) => {
    try {
    } catch (err) {
      setErr("Error fetching Tracking data.");
      console.log(err);
    }

    const response = await axios.get(
      `${backendUrl}/user/getTrackingData?userEmail=${userEmail}`
    );

    if (response.data.trackingData) {
      setTableData(response.data.trackingData);
    }
  };
  const fetchUser = async (userEmail) => {
    try {
    } catch (err) {
      setErr("Error fetching Tracking data.");
      console.log(err);
    }

    const response = await axios.get(
      `${backendUrl}/user/fetchUser?userEmail=${userEmail}`
    );

    if (response.data.user) {
      setSelectedUser(response.data.user);
    }
  };

  const trackingSitesColumns = [
    {
      field: "S.No",
      headerName: "S.No",
      width: 80,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "500",
            color: "black",
            textAlign: "center",
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center", // Center horizontally
            height: "100%",
          }}
        >
          {params.api.getAllRowIds().indexOf(params.id) + 1}
        </Box>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      minWidth: 200,
      headerAlign: "center",

      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#1a1a1a",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "position",
      headerName: "Position",
      flex: 1.5,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "company",
      headerName: "Company",
      flex: 1.5,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "linkedin",
      headerName: "LinkedIn",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <a
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontSize: "12px",
              color: "#0073b1",
              textDecoration: "none",
            }}
          >
            View Profile
          </a>
        </Box>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.5,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              color: "#1a1a1a",
              wordWrap: "break-word",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "open_trucker",
      headerName: "Open Trucker",
      flex: 1.5,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              color: "#1a1a1a",
              wordWrap: "break-word",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date/Time",
      flex: 1.5,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        // Check if value exists and is valid
        const date = params.value ? new Date(params.value) : null;

        // Format the date manually
        const options = {
          weekday: "long",
          day: "numeric",
          month: "short",
          year: "numeric",
        };
        const timeOptions = {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        };
        const formattedDate = date
          ? `${date.toLocaleDateString(
              "en-US",
              options
            )} / ${date.toLocaleTimeString("en-US", timeOptions)}`
          : "Invalid Date";

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                color: "#1a1a1a",
                wordWrap: "break-word",
              }}
            >
              {formattedDate}
            </Typography>
          </Box>
        );
      },
    },
  ];

  const sitesColumns = [
    {
      field: "S.No",
      headerName: "S.No",
      width: 80,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "500",
            color: "black",
            textAlign: "center",
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center", // Center horizontally
            height: "100%",
          }}
        >
          {params.api.getAllRowIds().indexOf(params.id) + 1}
        </Box>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      minWidth: 200,
      headerAlign: "center",

      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#1a1a1a",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.5,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "age",
      headerName: "Age",
      flex: 1.5,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "city",
      headerName: "City",
      flex: 1.5,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              color: "#1a1a1a",
              wordWrap: "break-word",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1.5,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              color: "#1a1a1a",
              wordWrap: "break-word",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "Tracker",
      headerName: "Track",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              fetchTrackingData(params.row.email); // Correctly update the state
              setSelectedUserName(params.row.name);
              toggleTrackerModal(); // Call the toggle function to open the modal
            }}
            size="sm"
            color="#ffff"
            style={{
              backgroundColor: "#2F2F3F",
              color: "white",
            }}
          >
            Tracker
          </Button>
        </Box>
      ),
    },
    {
      field: "profile",
      headerName: "Profile",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              fetchUser(params.row.email); // Correctly update the state
              setSelectedUserName(params.row.name);
              toggleProfileModal(); // Call the toggle function to open the modal
            }}
            size="sm"
            color="#ffff"
            style={{
              backgroundColor: "#2F2F3F",
              color: "white",
            }}
          >
            Profile
          </Button>
        </Box>
      ),
    },
  ];

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#f0f0f0",
      borderBottom: "2px solid #ddd",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
      fontSize: "14px",
    },
    "& .MuiDataGrid-row": {
      "&:nth-of-type(odd)": {
        backgroundColor: "#fafafa",
      },
      "&:nth-of-type(even)": {
        backgroundColor: "#fff",
      },
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    },
    "& .MuiDataGrid-cell": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }));
  const [numOfProductPerPage, setnumOfProductPerPage] = useState(10);

  const [search, setSearch] = useState("");

  const [loading, setloading] = useState(false);

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, left: open });
  };

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer admin toggleDrawer={toggleDrawer} state={state} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <SnackbarComponent
        err={err}
        success={success}
        setErr={setErr}
        setSuccess={setSuccess}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        {user?.userType === "admin" ? (
          <AppBar
            title="Talent Sync Admin Dashboard"
            toggleDrawer={toggleDrawer}
          />
        ) : (
          <AppBar title="Talent Sync Tracker" toggleDrawer={toggleDrawer} />
        )}

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        ></Menu>

        <Grid container p={2}>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            >
              <TitleWithSearchBar
                title="Tracker"
                search={search}
                setSearch={setSearch}
                halfWidth
              />

              <StyledDataGrid
                rows={
                  users?.filter((val) => {
                    const searchLower = search.toLowerCase();
                    return (
                      val?.name?.toLowerCase().includes(searchLower) ||
                      val?.email?.toLowerCase().includes(searchLower) ||
                      val?.city?.toLowerCase().includes(searchLower) ||
                      val?.country?.toLowerCase().includes(searchLower)
                    );
                  }) || []
                }
                columns={sitesColumns}
                pagination
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: numOfProductPerPage },
                  },
                }}
                pageSizeOptions={[5, 10, 100]}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <BootstrapDialog
          onClose={() => {
            setTrackerModalOpen(false);
          }}
          aria-labelledby="customized-dialog-title"
          open={trackerModalOpen}
        >
          <DialogTitle
            sx={{
              m: 0,
              p: 2,
              fontSize: "18px",
              fontWeight: "500",
              fontFamily: "DM Sans",
              color: "green",
            }}
            id="customized-dialog-title"
          >
            {selectedUsername}' Tracking Data
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              setTrackerModalOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            dividers
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {tableData && (
              <StyledDataGrid
                rows={tableData}
                columns={trackingSitesColumns}
                pagination
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: numOfProductPerPage },
                  },
                }}
                pageSizeOptions={[5, 10, 100]}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
              />
            )}
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{
                height: "40px",
                border: "1px solid #c8c8c8",
                backgroundColor: "#FFF",
                boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
                textTransform: "none",
                borderRadius: "8px",
                color: "black",
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "DM Sans",
                "&:hover": {
                  backgroundColor: "#FAFAFA",
                },
                px: 2.5,
              }}
              autoFocus
              onClick={() => {
                toggleTrackerModal();
              }}
            >
              Close
            </Button>
          </DialogActions>
        </BootstrapDialog>
        <BootstrapDialog
          onClose={() => {
            setProfileModalOpen(false);
          }}
          aria-labelledby="customized-dialog-title"
          open={profileModalOpen}
        >
          <DialogTitle
            sx={{
              m: 0,
              p: 2,
              fontSize: "18px",
              fontWeight: "500",
              fontFamily: "DM Sans",
              color: "green",
            }}
            id="customized-dialog-title"
          >
            {selectedUser?.name}'s Profile
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              setProfileModalOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            dividers
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: 3,
            }}
          >
            <Grid container spacing={2}>
              {[
                { label: "Name", value: selectedUser?.name },
                { label: "Email", value: selectedUser?.email },
                { label: "Contact", value: selectedUser?.contact },
                { label: "Age", value: selectedUser?.age },
                { label: "City", value: selectedUser?.city },
                { label: "State", value: selectedUser?.state },
                { label: "Country", value: selectedUser?.country },
                { label: "Nationality", value: selectedUser?.nationality },
                { label: "Residency", value: selectedUser?.residency },
                { label: "Job Title", value: selectedUser?.job_title },
                {
                  label: "Experience",
                  value: `${selectedUser?.experience} years`,
                },
                { label: "Industries", value: selectedUser?.industries },
                {
                  label: "Target Company Size",
                  value: selectedUser?.target_company_size,
                },
                { label: "Source", value: selectedUser?.source },
                {
                  label: "Education Level",
                  value: selectedUser?.education_level,
                },
                { label: "Cover Letter", value: selectedUser?.cover_letter },
                {
                  label: "Additional Info",
                  value: selectedUser?.additional_info,
                },
                {
                  label: "Subscription Bought",
                  value: selectedUser?.subscriptionBought ? "Yes" : "No",
                },
              ].map((field, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "DM Sans",
                      color: "#333",
                    }}
                  >
                    {field.label}:
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "DM Sans",
                      color: "#555",
                    }}
                  >
                    {field.value || "N/A"}
                  </Typography>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "DM Sans",
                    color: "#333",
                  }}
                >
                  Avatar:
                </Typography>
                {selectedUser?.avatar ? (
                  <a
                    href={selectedUser?.avatar}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#0073b1", textDecoration: "none" }}
                  >
                    View Avatar
                  </a>
                ) : (
                  <i>No avatar uploaded</i>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "DM Sans",
                    color: "#333",
                  }}
                >
                  Resume:
                </Typography>
                {selectedUser?.resume ? (
                  <a
                    href={selectedUser?.resume}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#0073b1", textDecoration: "none" }}
                  >
                    View Resume
                  </a>
                ) : (
                  <i>No resume uploaded.</i>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "DM Sans",
                    color: "#333",
                  }}
                >
                  Professional Certificate:
                </Typography>
                {selectedUser?.professional_certificate ? (
                  <a
                    href={selectedUser?.professional_certificate}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#0073b1", textDecoration: "none" }}
                  >
                    View Certificate
                  </a>
                ) : (
                  <i>No certificate uploaded</i>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "DM Sans",
                    color: "#333",
                  }}
                >
                  Education Certificate:
                </Typography>
                {selectedUser?.education_certificate ? (
                  <a
                    href={selectedUser?.education_certificate}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#0073b1", textDecoration: "none" }}
                  >
                    View Certificate
                  </a>
                ) : (
                  <i>No certificate uploaded</i>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{
                height: "40px",
                border: "1px solid #c8c8c8",
                backgroundColor: "#FFF",
                boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
                textTransform: "none",
                borderRadius: "8px",
                color: "black",
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "DM Sans",
                "&:hover": {
                  backgroundColor: "#FAFAFA",
                },
                px: 2.5,
              }}
              autoFocus
              onClick={() => {
                setProfileModalOpen(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
