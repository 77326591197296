import React from "react";
import { Alert, Slide, Snackbar } from "@mui/material";

const SnackbarComponent = ({ err, setErr, success, setSuccess }) => {
  const handleClose = () => {
    setErr(null);
    setSuccess(null);
  };
  return (
    <div>
      {err && (
        <Snackbar
          open={true}
          onClose={handleClose}
          TransitionComponent={(props) => (
            <Slide {...props} direction="right" />
          )}
          autoHideDuration={3000}
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: "danger",
              color: "black",
              border: "1px solid #008000",
            },
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            sx={{
              width: "100%",
              background: "#E3735E",
              color: "black",
              border: "1px solid #FF0000",
            }}
          >
            {err}
          </Alert>
        </Snackbar>
      )}
      {success && (
        <Snackbar
          open={true}
          onClose={handleClose}
          TransitionComponent={(props) => (
            <Slide {...props} direction="right" />
          )}
          autoHideDuration={6000}
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: "green",
              color: "black",
            },
          }}
        >
          <Alert
            severity="success"
            variant="filled"
            sx={{
              width: "100%",
              background: "#98FB98",
              color: "black",
              border: "1px solid #008000",
            }}
          >
            {success}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default SnackbarComponent;
