import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

const PrivateRoute = ({ role }) => {
  const navigate = useNavigate();

  const user = localStorage.getItem("TalentSyncUser");

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);

  return user ? <Outlet /> : null;
};

export default PrivateRoute;
