import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  alpha,
  InputBase,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ArrowBack } from "@mui/icons-material";

const SearchContainer = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "8px",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginBottom: "0px !important",
  marginRight: "10px",
  border: "1px solid #E0E2E7",
  height: "40px",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    // marginLeft: theme.spacing(2),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  fontFamily: "FiraSans",

  fontSize: "14px",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "14ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const TitleWithoutSearchBar = ({
  title,
  onClick,
  noBtn,
  search,
  setSearch,
  fullWidth,
  halfWidth,
  backBtn,
  backBtnClick,
  noSearch,
  showbutton,
  excelButtonOnClick,
}) => {
  const smallScreen = useMediaQuery("(max-width:650px)");
  return (
    <Box>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        px={2}
        pt={2}
      >
        <Grid item xs={12} md={fullWidth ? 4 : 6} sm={6}>
          <Stack direction="row" spacing={1}>
            {backBtn && (
              <Button
                onClick={backBtnClick}
                sx={{
                  color: "black",
                  height: "30px",
                  minWidth: "40px",
                }}
              >
                <ArrowBack sx={{ color: "black", fontSize: 24 }} />
              </Button>
            )}
            <Typography
              variant="h6"
              style={{
                color: "black",
                fontWeight: "500",
                fontSize: 18,
              }}
            >
              {title}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TitleWithoutSearchBar;
