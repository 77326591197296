import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  List,
  CssBaseline,
  IconButton,
  ListItem,
  ListItemText,
  ListItemIcon,
  SwipeableDrawer,
  ListItemButton,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MuiDrawer from "@mui/material/Drawer";

const drawerWidth = 230;

// Opened Drawer Styles
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

// Closed Drawer Styles
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

// Drawer Header
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

// Styled Drawer
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: "#1E1E2F",
  color: "#FFFFFF",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MenuDrawer({ toggleDrawer, state }) {
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  // Menu Items
  const drawerMenu = [
    {
      id: 0,
      text: "User Tracker",
      icon: <TroubleshootIcon />,
      route: "/admin-dashboard/home",
    },
    {
      id: 1,
      text: "Create User",
      icon: <AccountCircleIcon />,
      route: "/admin-dashboard/create-user",
    },
  ];

  const handleLogout = async () => {
    localStorage.removeItem("TalentSyncUser");

    setTimeout(() => {
      navigate("/"); // Navigate after the timeout
    }, 1000);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer("left", false)}
      onKeyDown={toggleDrawer("left", false)}
    >
      <List>
        <Typography
          variant="h6"
          sx={{ fontWeight: "700", fontFamily: "DM Sans", color: "#000" }}
        >
          Talent Sync
        </Typography>
        {drawerMenu.map((item) => (
          <ListItem key={item.text}>
            <ListItemButton sx={{ padding: 0 }}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  sx: {
                    whiteSpace: "pre-line",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const smallScreen = useMediaQuery("(max-width:700px)");

  return (
    <Box sx={{ display: "flex", height: "100vh", fontFamily: "DM Sans" }}>
      <CssBaseline />
      {smallScreen ? (
        <div>
          <SwipeableDrawer
            anchor="left"
            open={state.left}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
            PaperProps={{
              sx: {
                backgroundColor: "#1E1E2F",
                color: "#FFFFFF",
                width: drawerWidth,
              },
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              {/* Header */}
              <DrawerHeader
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: "1px solid #2F2F3F",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "700",
                    fontFamily: "DM Sans",
                    color: "white",
                  }}
                >
                  Talent Sync
                </Typography>
                <IconButton onClick={toggleDrawer("left", false)}>
                  <MenuOutlinedIcon style={{ color: "white" }} />
                </IconButton>
              </DrawerHeader>

              {/* Menu List */}
              <List sx={{ flexGrow: 1, py: 2 }}>
                {drawerMenu.map((item) => (
                  <ListItem
                    key={item.text}
                    onClick={() => navigate(item.route)}
                    sx={{
                      cursor: "pointer",
                      backgroundColor:
                        location.pathname === item.route
                          ? "#2F2F3F"
                          : "transparent",
                      "&:hover": {
                        backgroundColor: "white",
                      },
                    }}
                  >
                    <ListItemButton sx={{ padding: "10px 16px" }}>
                      <ListItemIcon
                        sx={{
                          color: "#FFFFFF",
                          minWidth: "40px",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.text}
                        primaryTypographyProps={{
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "DM Sans",
                          color: "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>

              {/* Logout Button */}
              <ListItem
                onClick={handleLogout}
                sx={{
                  cursor: "pointer",
                  borderTop: "1px solid #2F2F3F",
                  "&:hover": {
                    backgroundColor: "#2F2F3F",
                  },
                }}
              >
                <ListItemButton sx={{ padding: "10px 16px" }}>
                  <ListItemIcon
                    sx={{
                      color: "#FFFFFF",
                      minWidth: "40px",
                    }}
                  >
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Logout"
                    primaryTypographyProps={{
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "DM Sans",
                      color: "#FFFFFF",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </Box>
          </SwipeableDrawer>
        </div>
      ) : (
        <Drawer variant="permanent" open={open}>
          <DrawerHeader
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 2,
              backgroundColor: "#1E1E2F",
              color: "#FFFFFF",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "700", fontFamily: "DM Sans", color: "white" }}
            >
              Talent Sync
            </Typography>
          </DrawerHeader>

          {/* Menu List */}
          <List sx={{ flexGrow: 1, py: 2, backgroundColor: "#1E1E2F" }}>
            {drawerMenu.map((item) => (
              <ListItem
                key={item.text}
                onClick={() => navigate(item.route)}
                sx={{
                  cursor: "pointer",
                  backgroundColor:
                    location.pathname === item.route
                      ? "#2F2F3F"
                      : "transparent",
                  "&:hover": {
                    backgroundColor: "#2F2F3F",
                  },
                }}
              >
                <ListItemButton
                  sx={{
                    padding: open ? "10px 16px" : "10px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: "#FFFFFF",
                      minWidth: "40px",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      primary={item.text}
                      primaryTypographyProps={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: "DM Sans",
                        color: "#FFFFFF",
                      }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          {/* Logout Button */}
          <ListItem
            onClick={handleLogout}
            sx={{
              cursor: "pointer",
              position: "absolute",
              bottom: 0,
              width: "100%",
              backgroundColor: "#1E1E2F",
              borderTop: "1px solid #2F2F3F",
              "&:hover": {
                backgroundColor: "#2F2F3F",
              },
            }}
          >
            <ListItemButton sx={{ padding: open ? "10px 16px" : "10px" }}>
              <ListItemIcon
                sx={{
                  color: "#FFFFFF",
                  minWidth: "40px",
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary="Logout"
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontWeight: "500",
                    fontFamily: "DM Sans",
                    color: "#FFFFFF",
                  }}
                />
              )}
            </ListItemButton>
          </ListItem>
        </Drawer>
      )}
    </Box>
  );
}
