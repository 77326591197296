import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Backdrop,
  CircularProgress,
  Breadcrumbs,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const AppBar = ({ title, breadcrumbs, toggleDrawer }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const location = useLocation();
  const smallScreen = useMediaQuery("(max-width:650px)");
  const [loading, setloading] = useState(false);

  const crumbs = [
    { name: "Dashboard", path: "/dashboard" },
    { name: "Tracker", path: "/tracker" },
  ];

  useEffect(() => {
    const talentSyncUser = JSON.parse(localStorage.getItem("TalentSyncUser"));
    if (talentSyncUser && talentSyncUser.name) {
      setUser(talentSyncUser);
    }
  }, []);

  return (
    <Box
      sx={{
        height: "75px",
        px: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#F7F9FC",
        borderBottom: "1px solid #E0E0E0",
        fontFamily: "DM Sans, sans-serif",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Left Section */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {smallScreen && (
          <IconButton
            onClick={toggleDrawer("left", true)}
            sx={{ color: "#1A202C", mr: 2 }}
          >
            <MenuOutlinedIcon fontSize="large" />
          </IconButton>
        )}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              color: "#1A202C",
              fontWeight: "600",
              fontSize: smallScreen ? "16px" : "22px",
              lineHeight: 1.3,
              fontFamily: "DM Sans, sans-serif",
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AppBar;
